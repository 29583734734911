import {
    Abstract
} from './Abstract';

export class TMCustomer extends Abstract {
    constructor(session) {
        super('tm/Customer', session);
        this.name = null;
    }
}
